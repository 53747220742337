:root {
    --themeOrange: #ff4901;
    --themeYellow: #fcb822;
    --themeWhite: #ffffff;
    --textColor: #333;
  }
  
  .ourProducts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 150px 5%;
    background-color: var(--themeWhite);
  }

  .subHeading{
    padding-bottom: 5px;
    width: 1200px;
    max-width: 100%;
    border-bottom: #b9b9b9 1px solid;
  }

  @media screen and (max-width: 900px) {
    .ourProducts{
        padding-top: 150px;
    }
}

@media screen and (max-width: 500px) {
    .ourProducts{
        padding-top: 250px;
    }
}
  
  .heading {
    font-size: 2.5rem;
    font-family: "Exo", sans-serif;
    color: var(--textColor);
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .filtersAndProducts{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    flex-wrap: wrap;
    padding: 50px 0;
  }
  .productGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    flex: 1;
  }
  
  .productCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 1.5rem;
    transition: transform 0.3s ease;
  
    &:hover {
      transform: scale(1.05);
    }
  
    h3 {
      font-size: 1.2rem;
      margin: 1rem 0 0.5rem;
      color: var(--textColor);
    }
  
    p {
      font-size: 0.9rem;
      color: var(--textColor);
    }

    .productImage {
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      background-color: #E6E1CDA1;
    }
  
    .productImage img {
      width: 200px;
      height: auto;
      border-radius: 10px;
    }
  }
  
  .viewMoreButton {
    font-size: 1rem;
    padding: 10px 30px;
    margin-top: 4rem;
    border: 1px solid black;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  
    &:hover {
      background: linear-gradient(90deg, var(--themeOrange), var(--themeYellow));
      color: var(--themeWhite);
      border: none;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .productGrid {
      flex-direction: column;
      gap: 1.5rem;
      align-items: center;
      justify-content: center;
    }
  
    .productCard {
      width: 100%;
      max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 2rem;
    }
  
    .productCard {
      max-width: 100%;
      padding: 1rem;
     
    }
  
    .viewMoreButton {
      font-size: 1rem;
      padding: 8px 20px;
    }
  }
  

  .filters{
    width: 1200px;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 40px 0;
    button{
      padding: 5px 20px;
      border-radius: 30px;
      width: 200px;
      border: 1px solid rgb(203, 203, 203);
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
      
      &:hover{
        background-color: var(--themeOrange);
        color: var(--themeWhite)
      }
    }
  }

  .active{
    background-color: var(--themeOrange);
    color: var(--themeWhite)
  }


  .filterSection {
    width: 250px;
    max-width: 100%;
    padding: 20px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .filterSection h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: var(--textColor);
  }
  
  .priceSlider {
    margin-bottom: 20px;
  }
  
  .priceSlider input {
    width: 100%;
    margin: 10px 0;
  }
  
  .priceInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filterCategories ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .filterCategories li {
    margin: 10px 0;
  }
  
  .filterCategories input[type="checkbox"] {
    margin-right: 10px;
  }
  