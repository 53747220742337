/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Isidori-sans-reg";
  src: url(./Assets/IsidoriFont/IsidoraSans-Regular.woff);
}

@font-face {
  font-family: "Isidori-sans-bold";
  src: url(./Assets/IsidoriFont/IsidoraSans-Bold.woff);
}

@font-face {
  font-family: "Isidori-sans-med";
  src: url(./Assets/IsidoriFont/IsidoraSans-Medium.woff);
}

@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&display=swap');

/* font-famsily: "Exo", sans-serif; */
/* font-famsily: "Koulen", system-ui;*/

:root {
  --themeOrange: #ff4901;
  --themeYellow: #fcb822;
  --themeWhite: #ffffff;
  --textColor: #333;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Isidori-sans-reg";
}

body {
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #fff;
}


.transparentButton{
  background-color: transparent;
  border-radius: 20px;
  padding: 5px 20px;
  border: 1px solid var(--textColor);
  color: var(--textColor);
  text-decoration: none;
  transition: all 0.3s ease;
}

.transparentButton:hover{
  background-color: var(--themeOrange);
  color: var(--themeWhite);
  border: 1px solid var(--themeOrange);
}


.transparentWhiteButton{
  background-color: transparent;
  border-radius: 20px;
  padding: 5px 20px;
  border: 1px solid white;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.transparentWhiteButton:hover{
  background-color: var(--themeOrange);
  color: var(--themeWhite);
  border: 1px solid var(--themeOrange);
}


.colouredButton{
  background-color: var(--themeOrange);
  border-radius: 20px;
  padding: 5px 20px;
  border: none;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.colouredButton:hover{
  background-color: var(--themeYellow);
}