//// Foooter
///
///
///
/// 


:root {
    --themeBlack: #000000d6;
    --themeOrange: #FF4901;
    --themeYellow: #FCB822;
    --themeWhite: #FFFFFF;
  }


.footerSection {
    position: relative;
    height: auto;
    width: 100%;
    padding: 30px;
    border-top: 1px #0000006B solid;
    border-bottom: 1px #0000006B solid;
  }
  
  .footerLinks {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;
    gap: 30px;
  }
  
  .footerLogos {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    max-width: 600px;
  }

  .footerText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 0.9rem;
    p{
      color: grey;
      font-size: 0.7rem;
    }
    b{
      font-family: "Isidori-sans-bold";
      color: var(--themeOrange);
    }
  }

  .footerTextBox{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0.9rem;
    gap: 20px;
    div{
      width: 250px;
    }
  }
  

  @media screen and (max-width: 920px) {
    .footerTextBox{
      flex-wrap: wrap;
    }
  }
  
  .anantLogoFooter {
    height: 60px;
    width: auto;
  }
  
  .socials {
    display: flex;
    gap: 10px;
    img {
      height: 25px;
      width: auto;
      filter: brightness(0);
      &:hover{
        transform: scale(1.1);
        padding: 0 5px;
        filter: brightness(1);
      }
    }
    
  }

  .footerLinksContainer{
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .links {
    min-width: 130px;
    p {
      text-align: left;
      a {
        font-size: 0.9rem;
        color: rgb(69, 69, 69);
        text-decoration: none;
        font-weight: bolder;
        padding-bottom: 3px;
        b{
          font-family: "Isidori-sans-bold";
        }
        // border-bottom: 4px var(--themeOrange) solid;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        a {
          font-size: 0.9rem;
          color: var(--themeBlack);
          text-decoration: none;
        }
      }
    }
  }
  
  .socialMedia {
    text-align: center;
    margin-top: 20px;
  
    p {
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    .socialIcons {
      display: flex;
      justify-content: center;
      gap: 10px;
  
      a img {
        width: 24px;
        height: 24px;
        transition: transform 0.2s;
  
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  
  

  .copyright{
    width: 100%;
    padding: 20px 1rem;
    display: flex;
    justify-content: end;
    align-items: center;
    ul{
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      gap: 20px;
      li a{
        text-decoration: none;
        color: grey;
        font-size: 0.8rem;
      }
    }
  }