.orderDetailContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 120px;

  
}


.order-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  // background-color: #f5f5f5;
  padding-top: 120px;


}

    .order-title {
      margin-bottom: 20px;
      font-weight: bold;
      color: #333;
    }
    
    .order-detail-card {
      background-color: #fff;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
    
    .section-title {
      font-weight: bold;
      margin-bottom: 10px;
      color: #1a73e8;
    }
    
    .divider {
      margin: 10px 0;
    }
    
    .product-detail {
      padding: 10px 0;
    }
    
    .product-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .product-info {
      flex: 1;
    }
    
    .product-image {
      cursor: pointer;
      margin-left: 20px;
    }
    
    .product-img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    
    @media (max-width: 768px) {
      .product-item {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .product-image {
        margin-top: 10px;
        margin-left: 0;
      }
      .orderDetailContainer{
        flex-direction: column;
        align-items: center;
        padding-top: 250px;
      }
      .order-detail-container{
        flex-direction: column;
        align-items: center;
        padding-top: 250px;
      }
    }