/* Container styling */
.track-order-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 200px;
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Main box styling */
.track-order-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

/* Input group styling */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group label {
  font-weight: bold;
  color: #555;
}

.input-group input {
  width: 250px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

/* Separator styling */
.separator {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: #aaa;
}

.separator span {
  padding: 10px;
  border-top: 1px dashed #ddd;
  border-bottom: 1px dashed #ddd;
  color: #777;
}

/* Track button styling */
.track-button {
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
  background-color: #d32f2f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.track-button:hover {
  background-color: #c62828;
}

/* Footer styling */
footer {
  margin-top: 15px;
  font-size: 0.8em;
  color: #aaa;
}

/* Media Query for Mobile Screens (Max Width: 600px) */
@media (max-width: 600px) {
  .track-order-container {
    padding-top: 300px; /* Reduce padding for smaller screens */
  }

  h2 {
    font-size: 1.5em; /* Smaller heading size */
  }

  .track-order-box {
    flex-direction: column; /* Stack items vertically */
    padding: 15px; /* Smaller padding */
  }

  .input-group input {
    width: 100%; /* Full width for inputs on mobile */
  }

  .separator {
    margin: 15px 0; /* Add vertical space between groups */
  }

  .track-button {
    width: 100%; /* Full width button */
    font-size: 0.9em; /* Slightly smaller font size */
    text-align: center;
    
  }

  footer {
    font-size: 0.7em; /* Smaller footer text */
  }
}
