.messageSection{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.messageSectionImg{
    flex: 1;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 0.75s ease;
    &:hover{
        transform: scale(1.05);
        margin: 0 10px;
        border-radius: 20px;
    }
}

.messageSectionContent{
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.messageSectionText{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 15px;
    width: 100%;
    padding: 0 10%;
    h1{
        font-family: "Exo", sans-serif;
        font-size: 2.2rem;
        color: white;
    }
    span{
        font-size: 1rem;
        color: white;
    }
    a{
        margin-top: 25px;
    }
}

@media screen and (max-width: 800px) {
    .messageSection{
        flex-direction: column;
    }
    .messageSectionImg{
        flex: auto;
        height: 50vh;
        width: 100%;
    }
    .messageSectionContent{
        flex: auto;
        height: 70vh;
        width: 100%;
    }
    
}