.heroContainer {
    position: relative;
    width: 98%;
    min-height: 100vh; /* Full height of the viewport */
    overflow: hidden;
    margin: 0 auto;
    // margin-top: 7rem;
    border-radius: 20px;
  }

  .videoObject1{
    position: absolute;
    top: 0;
    left: 0;
    width: 70px;
    height: auto;
  }

  .videoObject2{
    position: absolute;
    top: 40%;
    right: 0;
    width: 70px;
    height: auto;
  }
  
  .heroContainer video{
    margin-top: 7rem;
    border-radius: 20px;
  }
  
  
  
  @media screen and (max-width: 1140px) {
    .heroContainer video{
      margin-top: 9rem;
    }
  }
  
  @media screen and (max-width: 500px) {
    .heroContainer video{
      margin-top: 15rem;
    }
  }
  
  .videoBackground {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover; /* Ensures the video covers the entire area */
    z-index: -1; /* Places the video behind the content */
  }
  
  .heroContent {
    position: absolute;
    bottom: 20%; /* Position it close to the bottom */
    left: 10%;
    transform: translateY(0); /* Keeps it aligned to the left */
    text-align: left; /* Aligns the text to the left */
    color: white;
    z-index: 2; /* Ensure content appears over the video */
    max-width: 600px; /* Restrict width for readability */
  }
  
  .heroContent h1 {
    // font-family: "Exo", sans-serif; 
    font-family: "Exo", sans-serif;
    font-size: 2.5rem; 
    margin-bottom: 30px; 
    line-height: 1.2; 
    margin-bottom: 80px; 
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .exploreButton {
    font-size: 1.2rem;
    padding: 10px 30px;
    border: 2px solid white;
    border-radius: 30px;
    background-color: transparent;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .exploreButton:hover {
    background-color: white;
    color: black;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .heroContent {
      bottom: 10%;
      left: 5%;
      max-width: 90%; /* Allow more space on mobile */
      text-align: center; /* Center align content for smaller screens */
    }
  
    .heroContent h1 {
      font-size: 1.8rem; /* Smaller font size for mobile screens */
      margin-bottom: 50px; /* Reduce bottom margin */
    }
  
    .exploreButton {
      font-size: 1rem; /* Adjust button size for mobile */
      padding: 8px 20px;
    }
  }
  
  @media (max-width: 480px) {
    .heroContent h1 {
      font-size: 1.5rem; /* Further reduce font size on very small screens */
      margin-bottom: 30px;
    }
  
    .exploreButton {
      font-size: 0.9rem; /* Adjust button size */
      padding: 6px 15px;
    }
  }


 //// PetMessage
 /// 
 
 .PetMessageSection{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 85%;
  position: relative;
}

.dogPic{
  position: absolute;
  bottom: -20px;
  left: -90px;
  width: 180px;
  height: auto;
}

.pawPic{
  position: absolute;
  bottom: -50px;
  right: -75px;
  width: 150px;
  height: auto;
}

.PetMessageSectionImg{
  flex: 1;
  height: auto;
  transition: all 0.75s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5% 0;
  &:hover{
      transform: scale(1.05);
      margin: 0 10px;
      border-radius: 20px;
  }
} 

.PetMessageSectionCircle{
  width: 80%;
  aspect-ratio: 1;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::after{
      content: "";
      position: absolute;
      top: 10%;
      left: -5px;
      width: 10px;
      height: 80%;
      background-color: var(--themeOrange);
  }
}

.PetMessageSectionCircle2{
  width: 80%;
  aspect-ratio: 1;
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::after{
      content: "";
      position: absolute;
      top: 10%;
      right: -5px;
      width: 10px;
      height: 80%;
      background-color: var(--themeYellow);  }
}

.PetMessageSectionContent{
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.PetMessageSectionText{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 15px;
  width: 100%;
  padding: 20px 10%;
  h1{
      font-family: "Exo", sans-serif;
      font-size: 1.6rem;
      color: var(--textColor);
  }
  span{
      font-size: 1rem;
  }
  a{
      margin-top: 25px;
  }
}

@media screen and (max-width: 800px) {
  .PetMessageSection{
      flex-direction: column;
  }
  .PetMessageSectionImg{
      flex: auto;
      height: 50vh;
      width: 100%;
  }
  .PetMessageSectionContent{
      flex: auto;
      height: 70vh;
      width: 100%;
  }
  .dogPic{
    left: -65px;
    width: 130px;
  }

  .pawPic{
    right: -50px;
    width: 130px;
  }
  
}


////textSection
/// 
/// 

.textSection{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 50px 10%;
  gap: 30px;
  h1{
      width: 500px;
      max-width: 100%;
      font-family: "Exo", sans-serif;
      font-size: 1.6rem;
      color: var(--textColor);
      text-align: center;
  }
}

.textBallsSection{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.Ball{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.3s ease;
  &:hover{
    margin: 0 20px;
  }
}

.Ball:nth-child(1){
  background-color: #FCB822;
}

.Ball:nth-child(2){
  background-color: #25848C;
}

.Ball:nth-child(3){
  background-color: #76B6EE;
}
