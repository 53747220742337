.slider {
    text-align: center;
    padding: 20px;
    
  }
  
  .sliderTitle {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #444;
  }
  
  .slideContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 850px;
    margin: 20px auto;
    flex-direction: row; 
    height: 450px; 
  }
  
  .textContent {
    max-width: 50%;
    padding-right: 20px;
    text-align: left;
  }
  
  .step {
    font-size: 1.2rem;
    color: #333;
    margin: 0;
  }
  
  .heading {
    font-size: 1.8rem;
    font-weight: bold;
    color: #009688;
    margin: 5px 0;
  }
  
  .description {
    font-size: 1rem;
    color: #666;
    margin: 10px 0 15px;
  }
  
  .slideButton {
    padding: 10px 20px;
    background-color: #009688;
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .imageContent img {
    max-width: 300px; /* Restrict image width */
    height: 200px;
    margin: auto;
    object-fit: contain;
    border-radius: 10px;
  }
  
  /* Bottom tabs styling */
  .sliderTabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .tab {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0 5px;
    border-bottom: 2px solid transparent;
    color: black;
    font-size: 20px;
    opacity: 0.6;
    font-weight: 500;
  }
  
  .tab.active {
    border-bottom: 2px solid #009688;
    opacity: 1;
    color: #009688;
  }
  
  /* Responsive styling for mobile */
  @media (max-width: 768px) {
    .slideContent {
      flex-direction: column; /* Stack content vertically on mobile */
      align-items: center;
      text-align: center;
    }
  
    .textContent {
      max-width: 100%;
      padding: 0;
    }
  
    .imageContent img {
      max-width: 200px; /* Smaller image size on mobile */
    }
  }
  