.cardContainer {
  width: 100%;
  max-height: 100vh;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.cardSection {
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
}

.card {
  position: relative;
  width: 550px;
  border-radius: 10px;
  max-width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
}

.cardImage {
  width: 100%;
  height: auto;
  display: block;
}

.cardText {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px 20px;
  background-color: #004e4a;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 10px;
  width: 90%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2;
  height: 20%;
}

.cardText h3 {
  margin: 0;
  font-size: 18px;
}
