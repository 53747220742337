/* Main container */
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: white;
}

/* Section container: Two-column layout */
.sectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  gap: 50px; /* Adds space between columns */
}

/* Left-side content */
.leftContent {
  flex: 1;
  z-index: 2;
}

.leftContent h1 {
  font-family: "Krona One", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
  line-height: 1.2;
  margin-bottom: 20px;
}

/* Gradient button */
.ctaButton {
  width: 200px;
  height: 50px;
  background: linear-gradient(90deg, #ff7e29, #ffd200);
  border-radius: 30px;
  cursor: pointer;
  margin-top: 30px;
}

/* Right-side content */
.rightContent {
  flex: 1;
  z-index: 2;
  max-width: 600px;
}

.rightContent p {
  font-size: 1.3rem;
  color: #666;
  line-height: 1.6;
  text-align: justify;
  margin-bottom:30px;
}

.imageContainer {
  position: absolute;
  bottom: -120%; /* Adjust to move image partially off-screen */
  left: 0;   /* Adjust to move image partially off-screen */
  z-index: 1;   /* Behind content */
}

.imageContainer img {
  width: 400px; /* Adjust based on the image size */
  height: auto;
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .sectionContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .leftContent h1 {
    font-size: 2.2rem;
  }

  .rightContent {
    max-width: 90%;
  }

  .imageContainer {
    bottom: -10%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .imageContainer img {
    width: 300px;
  }
}

@media (max-width: 480px) {
  .leftContent h1 {
    font-size: 1.8rem;
  }

  .rightContent p {
    font-size: 1rem;
  }

  .imageContainer img {
    width: 250px;
  }
}
