// ProductSpecifications.module.scss

:root {
    --themeBlack: #000000d6;
    --themeOrange: #FF4901;
    --themeYellow: #FCB822;
    --themeWhite: #FFFFFF;
  }
.specificationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
    font-family: Arial, sans-serif;
    width: 100%;
    background-color: var(--themeWhite);
    box-sizing: border-box;
}

.title {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.2;
}

.specificationsContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap; // To allow wrapping of columns on smaller screens
}

.specColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin: 0 30px;
    flex-basis: 20%;
}

.specItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
}

.specItem img {
    width: 50px;
    height: 50px;
    margin-bottom: 5px;

}

.specItem p {
    font-weight: bold;
    margin: 0;
    font-size: 1rem;
}

.specItem span {
    margin-top: 5px;
    font-size: 0.8rem;
}

.specificationsImage {
    flex-basis: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0; // Added margin to avoid crowding on smaller screens
    
}

.specificationsImage img {
    width: 300px;
    height: auto;
    transition: all 0.75s;
    border-radius: 10px;
    // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    &:hover{
        transform: scale(1.1);
    }
}

/* Responsive Styling for Mobile Devices */
@media (max-width: 768px) {
    .title {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .specificationsContent {
        flex-direction: column; // Stack columns vertically on mobile
        align-items: center;
        padding: 20px 0;
    }

    .specColumn {
        flex-basis: 100%;
        flex-direction: row;
        margin: 15px 0; // Center columns with spacing
    }

    .specItem img {
        width: 25px;
        height: 25px;
    }

    .specItem p {
        font-size: 0.9rem;
    }

    .specItem span {
        font-size: 0.8rem;
    }

    .specificationsImage img {
        width: 200px; // Smaller image for mobile
    }
}

/* Extra Small Devices */
@media (max-width: 480px) {
    .title {
        font-size: 1.8rem;
    }

    .specItem img {
        width: 20px;
        height: 20px;
    }

    .specItem p, .specItem span {
        font-size: 0.8rem;
    }

    .specificationsImage img {
        width: 180px; // Further reduced for very small screens
    }
}
