.testimonials{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    min-height: 100vh;
    padding: 50px 1rem;
    h1{
        font-size: 2.6rem;
        color: var(--textColor);
        font-family: "Exo", sans-serif;
    }
}

.testimonialsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
}

.testimonial{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;
    min-height: 90vh;
    padding: 1rem;
    border-radius: 10px;
    background-color: #D9D9D940;
    max-width: 100%;
    &:hover{
        margin: 0 10px;
        transform: scale(1.1);
    }
    transition: all 0.75s ease;

}

.testimonialImg{
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.testimonialContent{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    h2{
        font-size: 1.3rem;
        font-family: "Exo", sans-serif;
        margin: 10px 0;
    }
    a{
        text-decoration: none;
        color: white;
        background-color: var(--themeOrange);
        font-size: 0.9rem;
        padding: 1px 25px;
        border-radius: 10px;
        border: none;
        margin-bottom: 10px;
        &:hover{
            background-color: var(--themeYellow);
        }
    }
    span{
        font-size: 0.85rem;
        text-align: justify;
    }
}



.subscribe{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 1rem;
}

.subscribeContent{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 1rem;
    border-radius: 15px;
    gap: 20px;
    width: 100%;
    // background-color: var(--themeOrange) ;
    background: linear-gradient(to right, #76B6EE, #66A2D6);
    span{
        font-size: 0.9rem;
        color: white;
    }
}

.subscribeButton{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    input{
        border: none;
        color: var(--textColor);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: 0.9rem;
        padding: 7px 10px;
        width: 250px;
    }
    button{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        // background-color: rgb(255, 128, 0);
        background-color: #25848C;
        padding: 7px 10px;
        font-size: 0.9rem;
        color: white;
        width: 150px;
        transition: all 0.75s ease;
        letter-spacing: 4px;
        &:hover{
            background-color: var(--themeYellow);
        }
    }
}

@media screen and (max-width: 1100px) {
    .subscribeContent{
        flex-direction: column;
    }
}