:root {
    --themeBlack: #000000d6;
    --themeOrange: #FF4901;
    --themeYellow: #FCB822;
    --themeWhite: #FFFFFF;
  }

.productPage {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 100px;
    overflow-x: hidden;

    /* Left Section: Images */
    .imageSection {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        padding: 3%;
        // width: 600px;
        // max-width: 100%;
        gap: 20px;
        // background-color: #FCB8223B;
        border-radius: 10px;
    }

    .imageThumbnails {
        display: flex;
        width: 100%;
        // flex-direction: column
        justify-content: center;
        align-items: center;
        gap: 30px;
    }

    .thumbnail img {
        width: 120px;
        height: auto;
        border-radius: 10px;
        // border: 1px solid #ccc;
        background-color: white;
        transition: transform 0.3s ease;
        border: 1px solid rgb(157, 157, 157);
        &:hover {
            transform: scale(1.05);
        }
    }

    /* Main Image */
    .mainImage {
        // background-image: url(../../Assets/EllipseCenter.png);
        // background-repeat: no-repeat;
        // background-position: center;
        // background-size: 100%;
        background-color: white;
        // border-radius: 50%;
        width: 100%;
        // aspect-ratio: 1;
        height: auto;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // border: 1px solid rgb(157, 157, 157);
        img {
            width: 75%;
            // transform: scale(1.2);
            height: auto;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    .shadow{
      transform: translateY(-40px);
    }

    // .increaseDecreaseButtons{
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;
    //     background-color: #ccc;
    //     button{
    //       width: 25px;
    //       height: 25px;
    //       padding: 3px;
    //       img{
    //         width: 100%;
    //         height: 100%;
    //       }
    //     }
    //   }
      
    //   .quantityInput[type=number]::-webkit-inner-spin-button, 
    //   .quantityInput[type=number]::-webkit-outer-spin-button {
    //     opacity: 1;
    //   }
    .increaseDecreaseButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      
        button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 5px;
      
          img {
            width: 15px;
            height: auto;
          }
      
          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }
        }
      }

    /* Right Section: Product Details */
    .productDetails {
        // background-color: #76B6EE17;
        padding: 3%;
        border-radius: 10px;
        flex: 1;
        .productHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h1 {
                font-size: 2rem;
                font-weight: bold;
                font-family: "Isidori-sans-bold"; 
            }

            .productRating {
                font-size: 1rem;
                color: var(--themeBlack);
            }
        }

        .price {
            font-size: 1.5rem;
            font-weight: bold;
            color: #333;
            margin-bottom: 20px;
        }

        .description {
            font-size: 1rem;
            line-height: 1.6;
            margin-bottom: 20px;
        } 
     

        .features {
            list-style-type: disc;
            margin-left: 20px;
            margin-bottom: 20px;

            li {
                margin-bottom: 10px;
                font-size: 1rem;
                line-height: 1.6;
            }
        }

        .addToCartBtn {
            padding: 10px 40px;
            background-color: #007bff00;
            color: var(--themeBlack);
            border: 1px solid rgb(157, 157, 157);
            // margin-bottom: 20px;
            background-color: #FFFFFF;
            border-radius: 25px;
            font-size: 1rem;
            font-family: "Isidori-sans-med";
            cursor: pointer;
           
            &:hover {
                background-color: var(--themeYellow);
            }
        }

        .serviceTimeDropdown{
            padding: 15px 20px;
            // margin-left: 20px;
            border: 1px solid rgb(157, 157, 157);
            border-radius: 25px;
            font-size: 1rem;
        }
    }
}

/* Responsive Design */
@media (max-width: 1050px) {
    .productPage {
        flex-direction: column;
        align-items: center;
        padding-top: 250px;
    

        .imageSection {
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

    }      

    .mainImage img {
        width: 100%;
        height: auto;
    }

    .imageThumbnails {
        width: 100%;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center;

        .thumbnail img {
            width: 100px;
        }
    }

    .productDetails {
        width: 100%;
    }
}

.cartSection {
    display: flex;
    align-items: center;  /* Vertically align items */
    gap: 10px;            /* Space between dropdown and button */
    margin-bottom: 20px;
    flex-wrap: wrap;      /* Ensure items wrap on smaller screens */
  }
  
  /* Service Time Dropdown Styling */
  .serviceTimeDropdown {
    width: 200px;            /* Fixed width for the dropdown */
    padding: 8px;            /* Padding inside the dropdown */
    border: 1px solid #ccc;  /* Light border */
    border-radius: 5px;      /* Rounded corners */
    background-color: #fff;  /* Background color */
    font-size: 16px;         /* Font size for text */
    color: #333;             /* Text color */
    cursor: pointer;         /* Pointer cursor on hover */
    transition: border-color 0.3s ease-in-out;
  
    &:hover {
      border-color: #2196f3;  /* Blue border on hover */
    }
  
    &:focus {
      outline: none;           /* Remove default outline */
      border-color: #4caf50;   /* Green border on focus */
    }
  }
  
  /* Add to Cart Button Styling */
//   .addToCartBtn {
//     padding: 12px 20px;         /* Padding inside the button */
//     background-color: #4caf50;  /* Green background */
//     color: white;               /* White text color */
//     border: none;               /* Remove border */
//     border-radius: 5px;         /* Rounded corners */
//     font-size: 16px;            /* Font size for button text */
//     cursor: pointer;            /* Pointer cursor on hover */
//     transition: background-color 0.3s ease;
     
  
//     &:hover {
//       background-color: #388e3c; /* Darker green on hover */
//     }
//   }
  
  /* Responsive Design: Move button to next line on smaller screens */
  @media (max-width: 600px) {
    .cartSection {
      flex-direction: column;  /* Stack items vertically */
      align-items: stretch;    /* Stretch items to fill the container */
    }
  
    .addToCartBtn {
      width: 100%;             /* Full width button on small screens */
    }
  
    .serviceTimeDropdown {
      width: 100%;             /* Full width dropdown on small screens */
    }
  }
  


  .specifications{
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    width: 100%;
    padding: 5%;
    padding-right: 0;
    background: linear-gradient(to bottom, #FC8B22, var(--themeYellow));
    position: relative;
    h1{
      color: white;
      font-family: "Exo", sans-serif;    
      font-size: 2rem;
    }

    &::after{
      content: "";
      width: 50%;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.186);
      border-top-right-radius: 120px;
    }

    &::before{
      content: "";
      width: 80%;
      height: 60px;
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.086);
      border-bottom-left-radius: 120px;
    }
  }

  .specificationsContainer{
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;

    ul{
      flex: 1;
      list-style: circle;
      color: white;
      font-family: "Isidori-sans-med";
      font-size: 1rem;
    }
  }

  .specificationImage{
    width: 500px;
    height: 400px;
    // background-image: url(../../Assets/blog1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
  }


  @media screen and (max-width: 1150px) {
    .specificationsContainer{
      flex-direction: column;
      padding-right: 5%;
    }

    .specificationImage{
      width: 100%;
      height: 600px;
    }
     
  }


  .productFeatures{
    display: flex;
    gap: 10px;
    align-items: flex-start;
    flex-wrap: wrap;
  } 

  .productFeatureSingle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 100px;
    max-width: 100%;
    text-align: center;
    
    span{
      font-size: 0.6rem;
    }
  }

  .featureIcon{
    width: 100px;
    aspect-ratio: 1;
    border-radius: 10px;
    background-color: #76B6EE;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 70%;
      height: auto;
      filter: brightness(0);
    }
  }


  