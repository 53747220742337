:root {
    --themeOrange: #ff4901;
    --themeYellow: #fcb822;
    --themeWhite: #ffffff;
    --textColor: #333;
  }
  
  .checkoutPage {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 150px;
  }
  
  .heading {
    text-align: left;
    font-size: 2.5rem;
    font-family: "Isidori-sans-bold";
    margin-bottom: 30px;
  }
  
  .checkoutContent {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .addressSection, .summarySection {
    width: 45%; /* Both sections take half of the available width */
    background-color: var(--themeWhite);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .addressSection h2, .summarySection h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    border-bottom: var(--themeYellow) 4px solid;
    padding-bottom: 10px;
  }
  
  .addressSection p {
    font-size: 1.2rem;
    color: var(--textColor);
    margin: 10px 0;
  }
  
  .addressSection svg {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .addressForm input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .addressForm button {
    padding: 12px 25px;
    background-color: var(--themeYellow);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: "Isidori-sans-bold";
  }
  
  .addressForm button:hover {
    background-color: var(--themeOrange);
  }
  
  .summarySection ul {
    list-style-type: none;
    padding: 0;
  }
  
  .summarySection ul li {
    font-size: 1.2rem;
    color: var(--textColor);
    margin-bottom: 10px;
  }
  
  .totalAmount {
    display: flex;
    justify-content: space-between;
    border-top: #ccc 1px solid;
    margin-top: 20px;
    padding-top: 20px;
  }
  
  .totalAmount p {
    font-size: 1.4rem;
    color: var(--textColor);
  }
  
  .totalAmount h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--themeOrange);
  }
  
  .payBtn {
    width: 100%;
    padding: 15px;
    font-size: 1rem;
    background-color: var(--themeYellow);
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-family: "Isidori-sans-bold";
    margin-top: 20px;
  }
  
  .payBtn:hover {
    background-color: var(--themeOrange);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .checkoutPage{
      flex-direction: column;
      align-items: center;
      padding-top: 250px;
    }
    .checkoutContent {
      flex-direction: column;
      
    }
  
    .addressSection, .summarySection {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  