
:root {
  --themeBlack: #000000d6;
  --themePink: #DC25B7;
  --themeBlue: #3F89CF;
  --themeOrange: #FF4901;
  --themeYellow: #FCB822;
  --themeWhite: #FFFFFF;
}

.navBox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  // padding: 0 2rem;
  width: 100%;
  // padding-top: 1rem;
  // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.81);
  // border-radius: 30px;
  min-height: 5rem;
  height: auto;
  padding: 1.5rem 2rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.nav.nav-scrolled {
  color: white; /* Change text color after scrolling */
  background-color: var(--themeBlack);

  & .menu{
    div{
      background-color: white;
    }
    div:nth-child(2):after{
      background-color: white;
    }
    
  
  }
}
.nav.nav-scrolled .profile-menu .dropbtn {
  color: white;
  transition: all 0.3s ease-in-out;
}


.nav-links ul {
  display: flex;
  list-style: none;
  gap: 40px;
}

.nav-links ul li a {
  font-size: 0.8rem;
  color: inherit; /* Inherit color from parent */
  font-family: "Isidori-sans-bold";
  text-decoration: none;
  // font-family: "Krona One", sans-serif;
  transition: all 0.3s ease;
}

.nav-links ul li a:hover {
  color: var(--themeOrange); /* Hover to white */
}

.navLogoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
}

.button-icons {
  display: flex;
  align-items: center;
  width: auto;
  gap: 20px;
}

.button-icons img {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}

.nav-scrolled .button-icons img{
  filter: brightness(0) invert(1); ;
}

.profile-menu {
  .dropdown {
    position: relative;
    display: inline-block;

    .dropbtn {
      // background-color: #3F89CF;
      color: black;
      // color: white;
      padding: 10px;
      font-size: 14px;
      border: none;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      align-items: center;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;

      a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      p{
        background-color: #3F89CF;
        border-radius: 6px;
        color: #fff;
        text-align: center;
        font-family: "Exo", sans-serif;
        padding: 2%;
      }

      a:hover {
        background-color: #ddd;
      }
    }

    &:hover .dropdown-content {
      display: block;
    }

    // &:hover .dropbtn {
    //   background-color: #3F89CF;
    // }
  }
}

.auth-menu {
  display: flex;
  gap: 15px;

  .auth-btn {
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #3F89CF;
    color: white;
    text-decoration: none;
  }

  .auth-btn:hover {
    background-color: #2d6fb6;
  }
}

.topButton {
  color: white;
  font-size: 0.7rem;
  padding: 10px 35px;
  border-radius: 20px;
  // background: linear-gradient(to right, var(--themeOrange), var(--themeYellow));
  background-color: var(--themeBlack);
  text-decoration: none;
  font-family: "Krona One", sans-serif;
  transition: background-color 0.3s ease;
}

.topButton:hover {
  // background: linear-gradient(to right, var(--themeYellow), var(--themeOrange));
  background-color: var(--themeOrange);
}

.menu {
  float: left;
  padding: 2rem 0;
  cursor: pointer;
  position: relative;
  display: none;
  width: 3rem;
  z-index: 2;
}
.menu div {
  width: 3rem;
  height: 0.4rem;
  background-color: var(--themeBlack);
  margin-bottom: 0.4rem;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
  position: absolute;
}


.menu div:nth-child(1) {
  top: 1rem;
}
.menu div:nth-child(2) {
  top: 1.8rem;
}
.menu div:nth-child(2):after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 0.4rem;
  background-color: var(--themeBlack);
  -webkit-transition: all 350ms ease;
  -moz-transition: all 350ms ease;
  transition: all 350ms ease;
}
.menu div:nth-child(3) {
  top: 2.6rem;
}
.menu.active div:nth-child(1),
.menu.active div:nth-child(3) {
  width: 0;
}
.menu.active div:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.menu.active div:nth-child(2):after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg);
}

.menu-active{
  div{
    background-color: white;
  }
  div:nth-child(2):after{
    background-color: white;
  }
}

@media screen and (max-width: 1140px) {
  .menu {
      display: block;
      cursor: pointer;
  }

  .nav-links {
      background-color: var(--themeBlack);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-120vh);
      transition: all 0.75s ease;
  }

  .nav-links ul {
      flex-direction: column;
      gap: 20px;
  }
}

.nav-links-active {
  transform: translateY(0);
  ul li a{
    color: var(--themeWhite);
  }
}

@media screen and (max-width: 700px) {
  .button-icons img {
      width: 20px;
      height: 20px;
  }

  .topButton {
      font-size: 0.5rem;
      padding: 10px 18px;
  }
}

@media screen and (max-width: 500px) {
  .nav {
      gap: 10px;
  }

  .menu div:nth-child(2):after {

      width: 2rem;

  }

  .menu div{
     width: 2rem;
  }
}

.cart-link {
  text-decoration: none;
  color: inherit;
}

.cart-container {
  display: flex;
  align-items: center;
  position: relative;
}

.cart-icon {
  width: 30px;  /* Adjust the size of the cart icon */
  // margin-right: 8px;
}

.cart-text {
  font-size: 16px;
  font-weight: 500;
  color: #333; /* Text color */
  transition: color 0.3s ease;
}



/* Hover effect */
.cart-link:hover .cart-text {
  color: #ff5722; /* Change text color on hover */
}

.cart-link:hover .cart-icon {
  transform: scale(1.1);  /* Slight scale effect on hover */
}
.navbar-icon {
  font-size: 1.5em;
  // color: var(--themeBlack);
}

/* Badge-like styling for the quantity number */
.cart-quantity {
  background-color: #ff5722;
  border-radius: 12px;
  padding: 1px 6px;
  color: white;
  font-weight: bold;
  margin-bottom: 16px;
  display: inline-block;
}


.productsDropdown{
  display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img{
      width: 10px;
      height: auto;
    }
  }


}
.productsDropdownMenu{
  display: flex;
  // flex-direction: column;
  align-items: flex-start;
  transition: all 0.75s ease-in-out;
  justify-content: space-between;
  flex-wrap: wrap;
  transform: translateY(-100vh);
  gap: 10px;
  position: relative;
  width: 100%;
  height: 0;
  background-color: var(--themeYellow);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14px;
    // background: linear-gradient(to right, var(--themeOrange) 0%, #25848C00 100%);
    background: linear-gradient(to right, var(--themeYellow) 0%, var(--themeOrange) 100%);
  }
}

.productsDropdownMenu-active{
  transform: translateY(0);
  min-height: 80vh;
  display: flex;
}

.productsDropdownMenuContent{
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 1rem;
  padding-top: 2rem;
  height: 100%;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0.138);
  // border-right: #0000001c 1px solid;
  h3{
    font-size: 1.2rem;
    font-family: "Exo", sans-serif;
    font-weight: bold;
  }
}

.productsDropdownMenuContent:nth-child(2){
  background-color: #00000000;
}

.productsDropdownMenuContent:last-child{
  border-right: none;
}

.productsDropdownMenuPic{
  height: 100%;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../Assets/dropdownPic.png);
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

.productsDropdownMenuItems{
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;

  a{
    display: flex;
    align-items: center;
    gap: 15px;

    img{
      width: 70px;
      height: auto;
    }
    span{
      font-size: 1rem;
    }
  }
}

.line{
  width: 50px;
  height: 5px;
  background-color: var(--themeOrange);
  margin-bottom: 20px;
}

@media screen and (max-width: 520px) {
  .productsDropdownMenuContent{
    height: auto;
  }
}

.sales-banner {
  // background-color: #ff9900;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 10;
}
.navbar-icon {
  font-size: 1.5em;
  // color: var(--themeBlack);
}
