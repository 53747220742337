// .heroContainer {
//   width: 98%;
//   min-height: 100vh; /* Full height of the viewport */
//   overflow: hidden;
//   margin: 0 auto;
//   border-radius: 20px;
// }

// .videoOut{
//   position: relative;
//   margin-top: 7rem;
//   height: fit-content;
//   height: calc(100vh - 7rem);
// }

// .videoBackground {
//   position: absolute;
//   border-radius: 20px;
//   bottom: 0;
//   left: 0;
//   height: 100%;
//   min-width: 100%;
//   min-height: 100%;
//   width: auto;
//   object-fit: cover; /* Ensures the video covers the entire area */
//   z-index: -1; /* Places the video behind the content */
// }
.salesBannerWrapper{
  padding-top: 120px;
}

.mainBannerSwiper {
  width: auto;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 70%;
    height: 15px;
    z-index: 5;
  }
}

.heroContainer {
  // width: 98%;
  border-radius: 20px;
  width: auto;
}

.imageOut {
  // margin-top: 170px;
  height: calc(100vh - 170px);
  width: 100%;
  overflow-x: hidden;
  // border-radius: 20px;
}

.imageBackground {
  height: 100%;
  width: 100%;
  object-position: left;
  object-fit: cover;
}

// 


@media screen and (max-width: 1140px) {
  .imageOut{
    // margin-top: 200px;
  }
}

@media screen and (max-width: 500px) {
  .imageOut{
    // margin-top: 230px;
  }
  // .imageBackground {
  //   object-fit: scale-down;
  // }
}



.exploreButton {
  font-size: 1.2rem;
  padding: 10px 30px;
  border: 2px solid white;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.exploreButton:hover {
  background-color: white;
  color: black;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .heroContent {
    // bottom: 10%;
    // left: 5%;
    max-width: 90%; /* Allow more space on mobile */
    text-align: center; /* Center align content for smaller screens */
  }

  .heroContent h1 {
    font-size: 1.8rem; /* Smaller font size for mobile screens */
    margin-bottom: 50px; /* Reduce bottom margin */
  }

  .exploreButton {
    font-size: 1rem; /* Adjust button size for mobile */
    padding: 8px 20px;
  }
}

@media (max-width: 480px) {
  .heroContent h1 {
    font-size: 1.5rem; /* Further reduce font size on very small screens */
    margin-bottom: 30px;
  }

  .exploreButton {
    font-size: 0.9rem; /* Adjust button size */
    padding: 6px 15px;
  }
}

.mainBannerSingle {
  width: 90%;
  height: 100%;
  display: flex;
  gap: 20px;
  padding: 0 5%;
  justify-content: flex-end;
  flex-direction: column;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 5%;
    font-family: "Isidora-med";
  }

  a {
    color: white;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px 20px;
    text-align: center;
    font-family: "Isidora-med";
    border: 1px solid white;
    border-radius: 20px;
    max-width: 200px;
  }
  overflow-x: hidden;

}