:root {
  --themeBlack: #000000d6;
  --themeOrange: #FF4901;
  --themeYellow: #FCB822;
  --themeWhite: #FFFFFF;
}

.productDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  font-family: Arial, sans-serif;
  background-color: black; // Match the screenshot background
  color: var(--themeYellow); // Match the font color
  width: 100%;
}

.title {
  text-align: center;
  margin-bottom: 10px;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--themeWhite);
}

.tagline {
  text-align: center;
  font-size: 1rem;
  color: var(--themeWhite);
  margin-bottom: 20px;
}

.productDetailContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0;
}

.featuresColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex-basis: 25%;
  color: var(--themeWhite);
}

.productFeatureSingle {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;
}

.featureIcon {
  width: 50px;
  height: 50px;
}

.featureIcon img {
  width: 100%;
  height: auto;
  filter: brightness(0) invert(1);
}

.productImageContainer {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0;
}

.productImageContainer img {
  width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.75s;
}

.shadow{
  filter: brightness(0) invert(1);
  transform: translateY(-40px);
}

/* Responsive Styling for Mobile Devices */
@media (max-width: 768px) {
  .title {
      font-size: 2rem;
  }

  .productDetailContent {
      flex-direction: column;
      align-items: center;
  }

  .featuresColumn {
      flex-basis: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
  }

  .productImageContainer img {
      width: 200px;
  }
}
