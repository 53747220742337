 ///Slider
///

.PetSliderContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 8%;
    // padding: 0 5%;
    position: relative;
    justify-content: center;
  }

  .PetOnSide{
    position: absolute;
    bottom: -30%;
    left: 0;
    width: 250px;
    height: auto;
  }
  
  .PetSwiper {
    width: 100%;
  }
  
  .PetSwiperSlide {
    width: 270px;
    height: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    padding: 100px 0;
  }
  
  .PetSwiperImage {
    width: 270px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease;
    position: relative;
    img {
      width: 60%;
      aspect-ratio: 1;
    }
  }
  
  .PetSwiperImage::after {
    background-image: url("../../Assets/EllipseCenter.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 270px;
    opacity: 0;
    aspect-ratio: 1;
    content: "";
    position: absolute;
    z-index: -1;
    transition: all 0.75s ease;
  }
  
  .PetSwiperText {
    width: 100%;
    max-width: 100%;
    display: flex;
    transition: all 0.75s ease;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    opacity: 0;
    span {
      font-size: 0.9rem;
      text-align: center;
      b {
        font-size: 1.1rem;
        font-family: "Isidori-sans-bold";
      }
    }
  }
  
  .swiper-slide.swiper-slide-active {
    .PetSwiperImage {
      scale: 1.2;
      &::after {
        opacity: 0;
      }
    }
    .PetSwiperText {
      opacity: 1;
    }
  }
  
