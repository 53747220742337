:root {
    --themeOrange: #ff4901;
    --themeYellow: #fcb822;
    --themeWhite: #ffffff;
    --textColor: #333;
  }
  
  .gifSection {
    display: flex;
    margin: 50px 0;
    align-items: center;
    justify-content: space-around;
    padding: 0 1rem; /* Adjust padding for the overall section */
    // background: linear-gradient(to right, var(--themeOrange), var(--themeYellow));
    background-color: var(--textColor);
    color: var(--themeWhite);
    position: relative;

    &::after{
      content: "";
      position: absolute;
      top: -10px;
      right: 0;
      width: 100%;
      height: 10px;
      background: linear-gradient(to left, #25848C 0%, #25848C00 50%);
    }

    &::before{
      content: "";
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 100%;
      height: 10px;
      background: linear-gradient(to left, #FF4901 0%, #25848C00 50%);
    }
  }
  
 

  .gifContainer {
    align-self: end;
    flex: 1;
    display: flex;
    align-items: end;
    justify-content: center;
    .mobileGif {
      width: 400px;
      aspect-ratio: 1;
      max-width: 100%; /* Limit the GIF size on larger screens */
      border-radius: 15px;
      transform: scale(1.5);
      transform-origin: bottom center;
  }
}

@media screen and (max-width: 850px) {
  .gifSection {
    flex-direction: column-reverse;
  }

  .gifContainer {
    align-self: center;
    align-items: center;
    .mobileGif {
      transform: scale(1.1);
    }
  }

  


}
  
  .textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 1.5rem;
    height: auto;
    gap: 20px;
    
  
    .heading {
      font-size: 2rem;
      // font-family: 'Krona One', sans-serif;;
      font-family: "Exo", sans-serif;
      margin-bottom: 1.5rem;
      color: white;
      span {
        font-weight: bold;
      }
  
      @media (max-width: 1200px) {
        font-size: 1.5rem;
      }
  
      @media (max-width: 768px) {
        font-size: 1rem;
      }
  
      @media (max-width: 480px) {
        font-size: 0.8rem;
      }
    }
  
    .description {
      font-size: 1rem;
      line-height: 1.6;
  
      @media (max-width: 1200px) {
        font-size: 0.9rem;
      }
  
      @media (max-width: 768px) {
        font-size:  0.9rem;
      }
  
      @media (max-width: 480px) {
        font-size: 0.8rem;
      }
    }
  }
  
  .emoji{
    animation: emojiAnim 5s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    span{
      color: white;
      font-size: 1rem;
    }
    img{
      width: 100px;
      height: auto;
    }
  }

  @keyframes emojiAnim {

    0%{
      transform: translateY(0);
    }

    50%{
      transform: translateY(-15px) scale(1.05);
    }

    100%{
      transform: translateY(0) scale(1);
    }
    
  }

  .emojiContainer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    gap: 40px;
  }


  .centerLogo{
    width: 100px;
    height: auto;
  }