.container {
  width: 96%;
  border-radius: 30px;
  height: 100vh; /* Full height of the viewport */
  position: relative;
  overflow: hidden; /* Ensure no overflow of content */
  margin: 100px auto;
  user-select: none;
}

.videoBackground {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire area */
  z-index: -1; /* Places the video behind the content */
}



.imageGradient {
  position: absolute;
  bottom: -20%; /* Adjust to move image partially off-screen */
  right: 0;
  z-index: 10; /* Ensure the image is above the gradient */
}

.imageGradient img {
  width: 400px; /* Adjust based on the image size */
  height: auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .imageGradient {
    bottom: -10%; /* Bring image higher for smaller screens */
    right: -10%; /* Adjust right position for tablets */
  }

  .imageGradient img {
    width: 300px; /* Smaller image size for tablets */
  }
}

@media (max-width: 480px) {
  .imageGradient {
    bottom: -5%; /* Further adjust for smaller screens */
    right: -5%; /* Adjust right position for smaller screens */
  }

  .imageGradient img {
    width: 200px; /* Smaller image size for mobile */
  }
}
