:root {
  --themeOrange: #ff4901;
  --themeYellow: #fcb822;
  --themeWhite: #ffffff;
  --textColor: #333;
}
.features-section {
    padding: 40px 20px;
    background-color: var(--themeWhite);
    text-align: center;
    margin: 5rem 0rem;
    max-height: fit-content;
  }
  
  .features-title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-family: "Isidora-med";
    color: var(--textColor);
  }
  
  .features-subtitle {
    font-size: 1rem;
    color: #000000;
    margin-bottom: 30px;
  }
  
  .features-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .feature-card {
    background: #FBFBFB;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    padding: 20px 25px;
    transition: transform 0.2s;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
  }
  
  .feature-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 2px solid #b4abab;
    padding: 2px;
    
  }
  
  .feature-title {
    font-size: 1.5rem;
    color: var(--textColor);
    
    font-family: "Isidora-med";
    margin-bottom: 15px;
  }
  
  .feature-description {
    font-size: 0.875rem;
    color: #000000;
    line-height: 1.7;
    
  }
  
  @media (max-width: 768px) {
    .features-container {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 500px) {
    // .feature-card {
    //   height: 60%;
    // }
  }
  