:root {
  --themeOrange: #ff4901;
  --themeYellow: #fcb822;
  --themeWhite: #ffffff;
  --textColor: #333;
}

.blogsPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 150px;
}

.heading {
  text-align: center;
  font-size: 2.5rem;
  font-family: "Isidori-sans-bold";
  margin-bottom: 30px;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-bottom: 10px;
  border-bottom: #ccc 1px solid;
  margin-bottom: 40px;

  .breadcrumb {
    font-size: 1rem;

    a {
      text-decoration: none;
      color: black;
    }

    span {
      color: #333;
    }
  }

  .filters {
    display: flex;
    gap: 15px;

    .yearFilter,
    .categoryFilter {
      padding: 10px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 20px;
      width: 150px;
      text-align: center;
    }
  }
}

.blogsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.blogCard {
  background: white;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .blogImage {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 20px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1rem;
    font-family: "Isidori-sans-bold";
    margin-bottom: 10px;
    padding: 10px 0;
    border-top: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
  }

  p {
    font-size: 0.8rem;
    text-align: justify;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
  }

  .readMoreBtn {
    padding: 10px 40px;
    background-color: #007bff00;
    color: var(--themeBlack);
    border: 1px solid rgb(157, 157, 157);
    margin-bottom: 20px;
    border-radius: 25px;
    font-size: 1rem;
    font-family: "Isidori-sans-med";
    cursor: pointer;
    &:hover {
      background-color: var(--themeYellow);
    }
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .blogsPage {
    padding-top: 250px;
  }
  .topBar {
    flex-direction: column;
    align-items: flex-start;

    .filters {
      margin-top: 10px;
    }
  }

  .blogsList {
    grid-template-columns: 1fr;
  }
}
