.sliderContainer{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 8%;
    // padding: 0 5%;
    justify-content: center;
}

.swiper{
    width: 100%;
    margin-bottom: 50px;
}

.swiperSlide{
    width: 270px;
    height: auto;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    padding: 20px; 
    border-radius: 30px;
}


.swiperImage{
    width: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.75s ease;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        width: 80%;
        aspect-ratio: 1;
    }
    // &::after{
    //     background-image: url('../../Assets/shadow.png');
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     width: 100%;
    //     height: 100px;
    //     opacity: 0;
    //     content: "";
    //     z-index: -1;
    //     transition: all 0.75s ease;
    // }
}

.swiperShadow{
    width: 100%;
    height: auto;
    img{
        width: 100%;
        height: auto;
    }
    opacity: 0;
    transition: all 0.75s ease;
    transform: translateY(-40px);
}

.swiperText{
    width: 100%;
    max-width: 100%;
    display: flex;
    transition: all 0.75s ease;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    opacity: 0;
    span{
        font-size: 0.9rem;
        text-align: center;
        b{
            font-size: 1.1rem;
            font-family: "Isidori-sans-bold";
        }
    }
}

.swiper-slide.swiper-slide-active{
    .swiperImage{
        scale: 1.2;
        // &::after{
        //     opacity: 1;
        // }
    }
    .swiperShadow{
        opacity: 1;
    }
    .swiperText{
        opacity: 1;
    }
}