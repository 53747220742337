.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;

  }
  .formContainer {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.2s ease;
  
    &:focus {
      border-color: #3b82f6;
      outline: none;
    }
  }
  
  .button {
    width: 100%;
    padding: 10px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s ease;
  
    &:hover {
      background-color: #2563eb;
    }
  }
  