.salesBanner {
  width: 100%;
  text-align: center;
  padding: 15px;
  transition: background-color 1s ease, color 1s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.bannerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: auto;
  flex-wrap: wrap;
}

/* Font sizes and layout adjustments for larger screens */
.bannerText {
  font-size: 1.5em;
  // width: 300px;
  max-width: 100%;
  font-weight: bold;
  text-transform: uppercase;
}

.subText {
  width: 300px;
  max-width: 100%;
  font-size: 1em;
  font-style: italic;
}

.countdown {
  max-width: 100%;
  display: flex;
  gap: 5px;
  font-weight: bold;
  font-size: 1.2em;
}

.countdown span {
  padding: 5px;
  font-size: 2rem;
  font-weight: bold;
}
